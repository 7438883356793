import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import { useTranslation } from "react-i18next";
import T1 from "./T1.jpg";
import T2 from "./T2.jpg";
const PDFExample = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className="page-header bg_img padding-tb">
        <div className="overlay"></div>
        <div className="container">
          <div className="page-header-content-area">
            <h4 className="ph-title">{t("Ethics")}</h4>
          </div>
        </div>
      </section>
      <section>
        <div className="flex justify-center flex-wrap md:flex-row flex-col gap-2 align-content-center">
          <img src={T1} alt="T1" className="zoom-image h-1/3 w-1/3 mx-4" />
          <img src={T2} alt="T2" className="zoom-image h-1/3 w-1/3 mx-4" />
        </div>
      </section>
      <Footer />
    </>
  );
};

export default PDFExample;
