/* eslint-disable react/jsx-no-undef */
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logout, reset } from "../features/auth/authSlice";
import Notification from "./Notification";

import { useEffect, useState } from "react";

function Header() {
  const [navbar, setNavbar] = useState(false);
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentLang = localStorage.getItem("I18N_LANGUAGE");

  if (currentLang == "en") {
    document.body.dir = "ltr";
  } else {
    document.body.dir = "rtl";
  }

  const lngs = [
    { code: "en", nativeName: "English" },
    { code: "ar", nativeName: "Arabic" },
  ];
  const { user } = useSelector((state) => state.auth);

  const onLogout = () => {
    dispatch(logout());
    dispatch(reset());
    navigate("/");
  };
  useEffect(() => {
    localStorage.getItem("I18N_LANGUAGE");
  }, []);

  const onChange = (e) => {
    localStorage.setItem("I18N_LANGUAGE", e);
    let lang = localStorage.getItem("I18N_LANGUAGE");
    if (lang == "en") {
      document.body.dir = "ltr";
      i18n.changeLanguage(lang);
      // document.location.reload();
    } else {
      document.body.dir = "rtl";
      i18n.changeLanguage(lang);
      // document.location.reload();
    }
  };
  return (
    <>
      <section className="">
        <header className="header-section">
          <div className="header-bottom">
            <div className="container">
              <div className="header-wrapper">
                <div className="logo">
                  <a href="/" className="text-5xl md:w-24 w-16">
                    <img
                      src="assets/images/logo/integrity.png"
                      className="rounded-none"
                      alt="logo"
                    />
                    {/* <SiPingdom /> */}
                  </a>{" "}
                  {/* {currentLang === "ar" ? (
                    <a
                      onClick={() => onChange("en")}
                      className="md:my-auto mx-2 "
                    >
                      <span className="hover:text-pink-600">
                        <i class="icofont-globe text-4xl w-full"></i>
                      </span>
                    </a>
                  ) : (
                    <a
                      onClick={() => onChange("ar")}
                      className="md:my-auto mx-2"
                    >
                      <span className="hover:text-pink-600">
                        <i class="icofont-globe text-4xl w-full"></i>
                      </span>
                    </a>
                  )} */}
                </div>

                {/* <select
                  class="p-1 text-dark rounded bg-transparent"
                  onChange={onChange}
                  // style={{ color: "#df01ae" }}
                >
                  <option
                    value="ar"
                    selected={currentLang == "ar" ? true : false}
                  >
                    العربية
                  </option>
                  <option
                    value="en"
                    selected={currentLang == "en" ? true : false}
                  >
                    English
                  </option>
                </select> */}

                {user ? (
                  <div className="menu-area">
                    {currentLang === "ar" ? (
                      <a
                        onClick={() => onChange("en")}
                        className="md:my-auto mx-2 "
                      >
                        <span className="hover:text-pink-600">
                          <i class="icofont-globe text-3xl w-full"></i>
                        </span>
                      </a>
                    ) : (
                      <a
                        onClick={() => onChange("ar")}
                        className="md:my-auto mx-2"
                      >
                        <span className="hover:text-pink-600">
                          <i class="icofont-globe text-3xl w-full"></i>
                        </span>
                      </a>
                    )}
                    <ul className="menu">
                      {user && user.result.role === 0 && (
                        <>
                          <li>
                            <a href="/gallery" className="hover:text-pink-600">
                              {t("Gallery")}
                            </a>
                          </li>{" "}
                          <li>
                            <a href="/calendar" className="hover:text-pink-600">
                              {t("Calendar")}
                            </a>
                          </li>
                          <li>
                            <a href="/profile" className="hover:text-pink-600">
                              {t("Profile")}
                            </a>
                          </li>{" "}
                          <li>
                            <a href="/contact" className="hover:text-pink-600">
                              {t("Contact")}
                            </a>
                          </li>
                        </>
                      )}

                      {user && user.result.role === 2 && (
                        <>
                          {/* <li>
                            <a href="/" className="hover:text-pink-600">
                              {t("Home")}
                            </a>
                          </li> */}
                          {/* <li>
                            <a href="/room">Rooms</a>
                          </li>{" "} */}

                          <li>
                            <a
                              href="/dashboard"
                              className="hover:text-pink-600"
                            >
                              {t("Dashboard")}
                            </a>
                          </li>
                        </>
                      )}
                      {user && user.result.role === 1 && (
                        <>
                          <li>
                            <a href="/qr" className="hover:text-pink-600">
                              {t("CheckIn")}
                            </a>
                          </li>
                          {/* <li>
                            <a href="/room">Rooms</a>
                          </li>{" "} */}
                        </>
                      )}
                      <li className="hidden md:flex items-center">
                        <div className="cart-ticket ">
                          <Notification />
                        </div>
                      </li>
                      <li>
                        <a onClick={onLogout} type="submit">
                          <span className="hover:text-pink-600">
                            <i class="icofont-power text-xl w-full"></i>
                          </span>
                        </a>
                      </li>
                    </ul>
                    <li className="flex md:hidden items-center">
                      <div className=" cart-ticket ">
                        <Notification />
                      </div>
                    </li>
                    <nav className=" bg-transparent ">
                      <div className="mx-auto md:hidden">
                        <div>
                          <div className="flex items-center justify-between py-3 md:py-5 md:block">
                            <div className="md:hidden">
                              <button
                                className="p-2 text-white rounded-md outline-none focus:border-gray-400 focus:border"
                                onClick={() => setNavbar(!navbar)}
                              >
                                {navbar ? (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-6 h-6"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-6 h-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M4 6h16M4 12h16M4 18h16"
                                    />
                                  </svg>
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="relative">
                          <div
                            className={`absolute pb-3 md:hidden ${
                              navbar ? "block" : "hidden"
                            }`}
                          >
                            {user && user.result.role === 0 && (
                              <ul className="bg-sky-900 rounded p-1 text-center items-center justify-center space-y-8 md:hidden content-center">
                                <li className="text-white-600 hover:text-blue-600">
                                  <a href="/gallery">{t("Gallery")}</a>
                                </li>
                                <li>
                                  <a
                                    href="/calendar"
                                    className="hover:text-pink-600"
                                  >
                                    {t("Calendar")}
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="/profile"
                                    className="hover:text-pink-600"
                                  >
                                    {t("Profile")}
                                  </a>
                                </li>{" "}
                                <li>
                                  <a
                                    href="/contact"
                                    className="hover:text-pink-600"
                                  >
                                    {t("Contact")}
                                  </a>
                                </li>
                                <li>
                                  <a onClick={onLogout} type="submit">
                                    <span className="hover:text-pink-600">
                                      <i class="icofont-power text-xl w-full"></i>
                                    </span>
                                  </a>
                                </li>
                              </ul>
                            )}
                            {user && user.result.role === 2 && (
                              <ul className="bg-sky-900 rounded p-1 text-center items-center justify-center space-y-8 md:hidden content-center">
                                <li className="text-white-600 hover:text-blue-600">
                                  <a href="/dashboard">{t("Dashboard")}</a>
                                </li>

                                <li>
                                  <a onClick={onLogout} type="submit">
                                    <span className="hover:text-pink-600">
                                      <i class="icofont-power text-xl w-full"></i>
                                    </span>
                                  </a>
                                </li>
                              </ul>
                            )}
                            {user && user.result.role === 1 && (
                              <ul className="bg-sky-900 rounded p-1 text-center items-center justify-center space-y-8 md:hidden content-center">
                                <li className="text-white-600 hover:text-blue-600">
                                  <a href="/qr">{t("CheckIn")}</a>
                                </li>
                                <li>
                                  <a onClick={onLogout} type="submit">
                                    <span className="hover:text-pink-600">
                                      <i class="icofont-power text-xl w-full"></i>
                                    </span>
                                  </a>
                                </li>
                              </ul>
                            )}
                          </div>
                        </div>
                      </div>
                    </nav>
                  </div>
                ) : (
                  <div className="menu-area">
                    <ul className="">
                      {/* <li>
                        <a href="/" className="hover:text-pink-600">
                          {t("Home")}
                        </a>
                      </li> */}
                      {currentLang === "ar" ? (
                        <a
                          onClick={() => onChange("en")}
                          className="md:my-auto mx-2 "
                        >
                          <span className="hover:text-pink-600">
                            <i class="icofont-globe text-3xl w-full"></i>
                          </span>
                        </a>
                      ) : (
                        <a
                          onClick={() => onChange("ar")}
                          className="md:my-auto mx-2"
                        >
                          <span className="hover:text-pink-600">
                            <i class="icofont-globe text-3xl w-full"></i>
                          </span>
                        </a>
                      )}
                      <a href="/login" className=" ticket-btn lab-btn">
                        <span className="hover:text-pink-600">
                          {" "}
                          {t("Login")}
                        </span>
                      </a>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </header>

        {/* <a href="#" className="scrollToTop">
          <i className="icofont-bubble-up"></i>
          <span className="pluse_1"></span>
          <span className="pluse_2"></span>
        </a> */}
      </section>
    </>
  );
}

export default Header;
